import React from "react";
import { graphql, navigate, PageProps } from "gatsby";
import styled from "styled-components";

import { Query } from "@graphql-types";
import {
  Container,
  H2,
  P,
  TextButton,
  VerticalSeparator,
} from "@util/standard";
import { BlocksContent } from "@sub";
import { colors, pages, TABLET_BREAKPOINT } from "@util/constants";

const Wrapper = styled(Container)`
  margin: 100px 0;
  position: relative;
`;

const Content = styled(Container)`
  width: 60%;
  margin: auto;
  flex-direction: column;
  z-index: 1;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 80%;
  }
`;

const Backdrop = styled.div`
  left: 0;
  bottom: 100px;
  position: absolute;
  width: 90%;
  background-color: ${colors.lightBlue};
  height: 630px;

  /* @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    top: -350px;
    height: 780px;
  } */
`;

interface Props extends PageProps {
  data: Query;
}

export default function MediaTemplate({ data }: Props) {
  const {
    allSanityMedia: {
      edges: [{ node: media }],
    },
  } = data;

  return (
    <Wrapper>
      <Content>
        <TextButton
          onClick={() => navigate(pages.media)}
        >{`< Back`}</TextButton>
        <Container
          justifyContent="space-between"
          alignItems="flex-end"
          margin="25px 0"
        >
          <Container>
            <VerticalSeparator />
            <H2 margin="0">{media.title}</H2>
          </Container>
          <P noMargin>{media.publishDate}</P>
        </Container>
        <BlocksContent blocks={media.content?._rawColumnContent} />
      </Content>
      <Backdrop />
    </Wrapper>
  );
}

export const query = graphql`
  query mediaQuery($slug: String) {
    allSanityMedia(filter: { slug: { current: { eq: $slug } } }) {
      edges {
        node {
          title
          publishDate(formatString: "DD MMM, YYYY")
          content {
            _rawColumnContent(resolveReferences: { maxDepth: 4 })
          }
        }
      }
    }
  }
`;
